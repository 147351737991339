var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-branch"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.branches,"searchable":false,"show-labels":true,"id":"filter-by-branch","deselect-label":"Убрать","label":"name","placeholder":"Филиал","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Филиал","track-by":"id"},on:{"input":() => {
                           _vm.filter_employees_statistic.branch_id = _vm.branch ? _vm.branch.id : null;
                           _vm.getEmployeesStatistic();
                         }},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+", "+_vm._s(props.option.address)+" ")]}},{key:"option",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+", "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(props.option.address))])]}}]),model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-responsible"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.employees_statistic,"searchable":true,"id":"filter-by-responsible","deselect-label":"Убрать","label":"title","placeholder":"Ответственный","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Ответственный","track-by":"id"},on:{"input":() => {
                           _vm.filter_employees_statistic.responsible_id = _vm.responsible ? _vm.responsible.id : null;
                           _vm.getEmployeesStatistic();
                         }},model:{value:(_vm.responsible),callback:function ($$v) {_vm.responsible=$$v},expression:"responsible"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1)])])]),(_vm.employees_statistic && _vm.onProgress === false)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed table-bordered"},[_vm._m(0),_c('tbody',_vm._l((_vm.employees_statistic),function(employee){return _c('tr',[_c('td',[_c('strong',[_vm._v(" "+_vm._s(employee.name)+" "),(employee.branch)?_c('span',{staticClass:"font-medium"},[_vm._v("("+_vm._s(employee.branch.title)+")")]):_vm._e()]),_c('br'),_c('small',[_vm._v(" "+_vm._s(employee.role.name)+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_callback_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_quick_callback_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_overdue_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.clients_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_exchange_or_buyout_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_crypto_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_credit_or_leasing_count)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_search_car_count)+" ")]),_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(" "+_vm._s(employee.tasks_in_work_count)+" ")])]),_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(" "+_vm._s(employee.tasks_count)+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(employee.tasks_archive_count)+" ")])])}),0)])]):_c('loading-processing')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v(" Сотрудник ")]),_c('th',[_vm._v(" Перезвон ")]),_c('th',[_vm._v(" Срочный перезвон ")]),_c('th',[_vm._v(" Просроченные ")]),_c('th',[_vm._v(" Клиенты ")]),_c('th',[_vm._v(" Обмен/выкуп ")]),_c('th',[_vm._v(" Криптовалюта ")]),_c('th',[_vm._v(" Кредит ")]),_c('th',[_vm._v(" Подбор ")]),_c('th',[_vm._v(" В работе ")]),_c('th',[_vm._v(" Всего задач ")]),_c('th',[_vm._v(" Архив ")])])])
}]

export { render, staticRenderFns }