<template>
  <div>
    <transition name="fade">
      <div class="form-group">
        <div class="row">
          <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
            <multiselect :allow-empty="true"
                         :options="branches"
                         :searchable="false"
                         :show-labels="true"
                         id="filter-by-branch"
                         @input="() => {
                             filter_employees_statistic.branch_id = branch ? branch.id : null;
                             getEmployeesStatistic();
                           }"
                         deselect-label="Убрать"
                         label="name"
                         placeholder="Филиал"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Филиал"
                         track-by="id"
                         v-model="branch">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
              <template slot="singleLabel" slot-scope="props">
                {{ props.option.title }}, {{ props.option.address }}
              </template>
              <template slot="option" slot-scope="props">
                {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
              </template>
            </multiselect>
          </label>

          <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-responsible">
            <multiselect :allow-empty="true"
                         :options="employees_statistic"
                         :searchable="true"
                         id="filter-by-responsible"
                         @input="() => {
                             filter_employees_statistic.responsible_id = responsible ? responsible.id : null;
                             getEmployeesStatistic();
                           }"
                         deselect-label="Убрать"
                         label="title"
                         placeholder="Ответственный"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Ответственный"
                         track-by="id"
                         v-model="responsible">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
          </label>
        </div>
      </div>
    </transition>

    <div class="table-responsive" v-if="employees_statistic && onProgress === false">
      <table class="table table-condensed table-bordered">
        <thead>
        <tr>
          <th>
            Сотрудник
          </th>
          <th>
            Перезвон
          </th>
          <th>
            Срочный перезвон
          </th>
          <th>
            Просроченные
          </th>
          <th>
            Клиенты
          </th>
          <th>
            Обмен/выкуп
          </th>
          <th>
            Криптовалюта
          </th>
          <th>
            Кредит
          </th>
          <th>
            Подбор
          </th>
          <th>
            В работе
          </th>
          <th>
            Всего задач
          </th>
          <th>
            Архив
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="employee in employees_statistic">
          <td>
            <strong>
              {{ employee.name }}
              <span class="font-medium" v-if="employee.branch">({{ employee.branch.title }})</span>
            </strong>
            <br/>
            <small>
              {{ employee.role.name }}
            </small>
          </td>
          <td class="text-center">
            {{ employee.tasks_callback_count }}
          </td>
          <td class="text-center">
            {{ employee.tasks_quick_callback_count }}
          </td>
          <td class="text-center">
            {{ employee.tasks_overdue_count }}
          </td>
          <td class="text-center">
            {{ employee.clients_count }}
          </td>
          <td class="text-center">
            {{ employee.tasks_exchange_or_buyout_count }}
          </td>
          <td class="text-center">
            {{ employee.tasks_crypto_count }}
          </td>
          <td class="text-center">
            {{ employee.tasks_credit_or_leasing_count }}
          </td>
          <td class="text-center">
            {{ employee.tasks_search_car_count }}
          </td>
          <td class="text-center">
            <strong>
              {{ employee.tasks_in_work_count }}
            </strong>
          </td>
          <td class="text-center">
            <strong>
              {{ employee.tasks_count }}
            </strong>
          </td>
          <td class="text-center">
            {{ employee.tasks_archive_count }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <loading-processing v-else></loading-processing>
  </div>
</template>

<script>
import BranchService from "@/services/BranchService";
import Multiselect from 'vue-multiselect';

export default {
  name: 'tasks-employees-statistics',

  components: {
    Multiselect,
  },

  props: {},

  data: () => ({
    employees_statistic: [],

    branches: [],
    branch: null,
    responsible: null,

    filter_employees_statistic: {
      branch_id: null,
      responsible_id: null,
    },

    onProgress: true,
  }),

  async created() {
    try {
      await this.getEmployeesStatistic();
      this.branches = await BranchService.getItems();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    async getEmployeesStatistic() {
      try {
        const tasks_employees_statistic_response = await API.apiClient.get('/tasks/statistic/employees', {
          params: {
            filters_query: this.filter_employees_statistic,
          }
        });

        this.employees_statistic = tasks_employees_statistic_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
  },
}
</script>